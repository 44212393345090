<template>
    <div id="mian">
        <div class="header">
            <span>账户列表</span>
        </div>
        <!-- 上面表单框 -->
        <div class="con_from">
            <p>
                <i>账户名称</i>
                <el-input v-model="input" style="width:200px;height:36px" suffix-icon="el-icon-search"	 placeholder="账户名称/账户编号"></el-input>
                <i class="jianju">所属商户</i>
                <el-select v-model="value" placeholder="所有" style="width:200px;height:36px;margin-right:105px">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" class="searchBt">查询</el-button>
            </p>
        </div>
        
        <div class="tab1">
            <table class="tab">
                <tr class="tab_title" >
                    <td style="width:60px;padding-left:15px;"><p>分账商户名称</p><p>分账商户编号</p></td>
                    <td style="width:150px"><p>所属商户</p></td>
                    <td style="width:60px"><p>总余额</p><p>不可用余额</p></td>
                    <td style="width:80px"><p>收款余额</p><p>收款冻结余额</p></td>
                    <td style="width:70px"><p>待清算金额</p></td>
                    <td style="width:80px"><p>可结算金额</p><p>可结算冻结金额</p></td>
                    <td style="width:80px"><p>担保金额</p><p>担保冻结金额</p></td>
                    <td><p>操作</p></td>
                </tr>
                <tr>
                    <td style="width:138px;padding-left:15px;"><p>2019-01-12 15:51:15</p> <p>2019-01-12 15:51:59</p></td>
                    <td style="margin-left:15px;"><p>深圳有数互动科技有限公司</p></td>
                    <td><p>12345678901234567</p> <p>0000678901234567</p></td>
                    <td><p>-</p> <p>-</p></td>
                    <td style="width:85px"><p>微信扫码支付</p></td>
                    <td><p>支付成功</p></td>
                    <td><p>支付成功</p></td>
                    <td style="width:66px"><p><i class="lianjie">查询</i><i class="lianjie" style="padding-left:10px;" >明细</i></p></td>
                </tr>
               
            </table>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            value1:"",
            value2:"",
        }
    },
    methods:{
        //新增按钮
        addclickHander(){
            this.$router.push("/user/businessList_shlb/addcommercial_jbxx")
        }
    }
    
}
</script>
<style scoped>
.lianjie{
    color: #008AFF;
}
.tab tr td p{
    width: 100%;
    text-align: center;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.tab_title{
    background:rgba(244,245,246,1);
    border-top:1px solid rgba(220,224,230,1); 
}
.tab1 .tab tr{
    padding-left:20px; 
    height: 60px;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab{
    border-collapse:collapse; 
    width: 100%;
    background: #fff;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab1{
    margin-top:20px; 
}
.jiaoyi_box p b{
    font-size:24px;
    font-family:PingFang SC;
    font-weight:600;
    color:rgba(72,184,182,1);
}
.jiaoyi_box p i{
    font-size:12px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(153,153,153,1);
}
.jiaoyi_box p{
    padding-top:18px; 
    width: 100%;
    text-align: center;
}
.jiaoyi{
    width: 100%;
    height: 90px;
    margin-top:20px; 
}
.jiaoyi_box{
    float: left;
    width: 485px;
    height: 90px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius:5px;
}
.jianju{
    
    padding-left:90px; 
}
.con_from p i{
    padding-right:12px; 
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(0,0,0,1);
}
.con_from p{
    padding-top:20px; 
}
.header span{
    padding-right:35px; 
    font-size:18px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(51,51,51,1);
}
.header{
    line-height: 60px;
    width:100%;
    height: 60px;
    border-bottom:1px solid  rgba(220,224,230,1);
}
#mian{
    padding-left:20px;
    padding-right:21px;  
    width: 96.5%;
    height: 100%;
}
.searchBt{
  float:right;
  background:#48B8B6;
  width:90px;
  height:36px;
  padding: 0;
  color: #FFFFFF;
}
</style>